import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart, updateQuantity } from '../../redux/slices/cartSlice';
import { placeOrder } from '../../api/user';
import { useNavigate } from 'react-router-dom';

const Checkout = () => {
  const cart = useSelector(state => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [note, setNote] = useState('');
  const [orderType, setOrderType] = useState('DINEIN');

  const handleUpdateQuantity = (id, newQuantity) => {
    if (newQuantity > 0) {
      dispatch(updateQuantity({ id, quantity: newQuantity }));
    } else {
      dispatch(removeFromCart(id));
    }
  };

  const handlePlaceOrder = async () => {
    try {
      const orderItems = cart.items.map(item => ({
        id: item.id,
        quantity: item.quantity,
      }));
      const customerInfo = localStorage.getItem("OG_phone")
      const response = await placeOrder(orderItems, note, orderType,customerInfo);
      if (response.success) {
        // Clear cart and navigate to order confirmation page
        // You'll need to implement these actions
        navigate('/order-confirmation');
      } else {
        // Handle error (show error message to user)
        console.error('Error placing order:', response.message);
      }
    } catch (error) {
      console.error('Error placing order:', error);
      // Handle error (show error message to user)
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Checkout</h1>
      {cart.items.map(item => (
        <div key={item.id} className="flex justify-between items-center mb-2 p-2 border-b">
          <span>{item.name}</span>
          <div>
            <button onClick={() => handleUpdateQuantity(item.id, item.quantity - 1)}>-</button>
            <span className="mx-2">{item.quantity}</span>
            <button onClick={() => handleUpdateQuantity(item.id, item.quantity + 1)}>+</button>
          </div>
          <span>₹{item.price * item.quantity}</span>
        </div>
      ))}
      <div className="mt-4 text-xl font-bold">Total: ₹{cart.total}</div>
      
      {/* Order type selection */}
      <div className="mt-4">
        <h2 className="text-lg font-semibold mb-2">Order Type</h2>
        <div className="flex space-x-4">
          <label className="flex items-center">
            <input
              type="radio"
              value="DINEIN"
              checked={orderType === 'DINEIN'}
              onChange={() => setOrderType('DINEIN')}
              className="mr-2"
            />
            Dine-in
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              value="TAKEAWAY"
              checked={orderType === 'TAKEAWAY'}
              onChange={() => setOrderType('TAKEAWAY')}
              className="mr-2"
            />
            Takeaway
          </label>
        </div>
      </div>

      {/* Note textarea */}
      <textarea
        className="w-full mt-4 p-2 border rounded"
        rows="3"
        placeholder="Add a note to your order"
        value={note}
        onChange={(e) => setNote(e.target.value)}
      ></textarea>
      
      <button 
        onClick={handlePlaceOrder}
        className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
      >
        Place Order
      </button>
    </div>
  );
};

export default Checkout;