import React, { useState, useEffect } from "react";
import banner from "../assets/banner.png";
import { Link, animateScroll as scroll } from "react-scroll";
import insta from "../assets/lets-icons_insta.svg";
import facebook from "../assets/mingcute_facebook-line.svg";
import globe from "../assets/ph_globe.svg";
import swiggy from "../assets/Group 18291.svg";
import zomato from "../assets/image 52.svg";
import { FaClock, FaThumbsUp, FaStar } from "react-icons/fa";
import RatingCard from "../../components/frontpage/review";
import Contact from "../../components/frontpage/contact";
import BottomMenu from "../../components/BottomMenu";
import StarRating from "../../components/StarRating";
import { Link as NavLink } from "react-router-dom";
import Edit from "../assets/edit.png";
import { getGalleryImages } from "../../api/cafe";
import { useNavigate } from "react-router-dom";
import vector from "../assets/Vector.svg";
import leftarrow from "../assets/leftarrow.svg";
import { toast } from "react-toastify";
import {
  getActiveOffers,
  getCafeDetails,
  getCafeReviewStats,
} from "../../api/cafe";
import { recordAnalyticsEvent } from "../../api/analytics";
import GalleryPopup from "../../components/user/GalleryPopup";
import OfferPopup from "../../components/user/OfferPopup";
import Offercard from "../../components/user/offerCard";

const FrontPage = ({ cafeData: propsCafeData, editable }) => {
  const [cafeData, setCafeData] = useState(propsCafeData || {});

  console.log({ cafeData });
  const image = `data:image/jpeg;base64,${cafeData.image}` || banner;
  const navigate = useNavigate();
  const [galleryImages, setGalleryImages] = useState([]);
  const [offers, setOffers] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);
  useEffect(() => {
    getGalleryImages(cafeData._id).then((res) => {
      setGalleryImages(res.data);
    });

    getActiveOffers(cafeData._id).then((res) => {
      setOffers(res.data);
    });
    fetchReviewStats();
    console.log({ galleryImages });
  }, [cafeData._id]);

  const fetchReviewStats = async () => {
    try {
      const response = await getCafeReviewStats();
      console.log("API Response:", response);
      if (response.success) {
        console.log("Setting stats:", response.data);
        setStats(response.data);
      }
    } catch (error) {
      console.error("Error fetching review stats:", error);
      toast.error("An error occurred while fetching review stats");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadCafeData = async () => {
      if (propsCafeData) {
        setCafeData(propsCafeData);
      } else {
        const storedCafeData = sessionStorage.getItem("OG_cafe");
        if (storedCafeData) {
          const parsedCafeData = JSON.parse(storedCafeData);
          setCafeData(parsedCafeData);
        } else {
          toast.error("Cafe data not found. Please try again.");
          navigate("/");
        }
      }
    };

    loadCafeData();
  }, [propsCafeData, navigate]);

  useEffect(() => {
    if (cafeData._id) {
      getGalleryImages(cafeData._id).then((res) => {
        setGalleryImages(res.data);
      });

      getActiveOffers(cafeData._id).then((res) => {
        setOffers(res.data);
      });

      fetchReviewStats();
    }
  }, [cafeData._id]);

  const handleScroll = (event) => {
    const container = event.currentTarget;
    const scrollTop = container.scrollTop;
    const itemHeight = container.offsetHeight;
    const currentIndex = Math.floor(scrollTop / itemHeight);
    setCurrentIndex(currentIndex);
  };
  const handleImageClick = (index) => {
    setSelectedImageIndex(index); // Update to set the image index
    const imageLink = `${window.location.origin}/cafe/gallery/${index}`;
    recordAnalyticsEvent("gallery_image_click", "CafeProfile", {
      cafe_id: cafeData?._id || "unknown",
      image_index: index,
      componentName: "CafeProfile",
      link: imageLink,
    });
  };

  const handleOfferClick = (offer) => {
    setSelectedOffer(offer);
  };

  const isNavbarFixed = false;
  return (
    <div className="w-full h-full">
      <div className="relative w-full h-[233px]">
        <img
          src={`data:image/webp;base64,${cafeData.image}`}
          alt="Banner"
          className="w-full h-full object-cover"
        />
        <div className="absolute font-[SF Pro Display] font-medium text-2xl md:text-3xl lg:text-4xl text-white top-[166px] left-5 w-4/5">
          {cafeData.name}
        </div>
        <div className="flex flex-row justify-between absolute top-5 bottom-0 left-0 right-0 px-4">
          <NavLink to={`/cafe/all-offers`}>
            <div className="bg-[#FFFFFF] bg-opacity-50 h-10 w-10 flex items-center justify-center rounded-lg">
              <img
                src={leftarrow}
                alt="Back"
              />
            </div>
          </NavLink>
        </div>
      </div>

      <div className="sticky top-0 h-[40px] bg-white flex items-center z-50 px-5">
        <nav className="overflow-x-auto w-full z-1000">
          <ul className="flex flex-row gap-2 items-center text-black w-full">
            {[
              "About",
              "Menu",
              "Social",
              "OrderOnline",
              "Offer",
              "Gallery",
              "Reviews",
              "Contact",
            ].map((item) => (
              <li key={item}>
                <Link
                  to={item}
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-40}
                  activeClass="text-purple-500"
                  className="cursor-pointer transition-colors duration-200 text-black text-xs font-bold"
                >
                  {item}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <section
        id="About"
        className="mt-12"
      >
        <div className="px-4 max-w-lg mx-auto">
          <section className="flex justify-between items-center mb-5">
            <div className="flex items-center">
              <FaClock size={30} />
              <div className="ml-3">
                <span className="block text-xs text-gray-500">Currently</span>
                <span className="block text-xl font-bold">
                  {cafeData.openstatus === true ? "Open" : "Closed"}
                </span>
              </div>
            </div>
            <div className="flex items-center">
              <FaThumbsUp size={30} />
              {stats && (
                <div className="ml-3">
                  {stats.totalReviews > 0 && (
                    <StarRating
                      rating={stats.averageRating}
                      count={stats.totalReviews}
                    />
                  )}
                  <span className="text-xl font-bold flex items-center">
                    {stats.totalReviews > 0
                      ? `${stats.averageRating}(${stats.totalReviews})`
                      : "No Reviews Yet"}
                  </span>
                </div>
              )}
            </div>
          </section>

          <section className="mb-5">
            <p>{cafeData.description}</p>
          </section>
        </div>
      </section>

      <hr className="border-t border-gray-300 my-4" />

      <section
        id="Menu"
        className="menu px-4 mb-4"
      >
        <div className="font-bold text-lg mb-8">Menu</div>
        <NavLink
          to={`/cafe-admin/menu`}
          className="w-full mt-4 flex justify-center items-center h-12 bg-purple-500 text-white rounded-lg font-bold"
          style={{ boxShadow: "2px 3px 0px 0px #000000" }}
        >
          View Menu
        </NavLink>
      </section>

      <hr className="border-t border-gray-300 my-4" />

      <section
        id="Social"
        className="social px-5 mb-4"
      >
        {(cafeData.website ||
          cafeData.instagram_link ||
          cafeData.facebook_link) && (
          <>
            <div className="font-bold text-lg mb-8">Social</div>
            <div className="flex flex-col mt-4 space-y-4">
              {cafeData.website && (
                <div className="flex items-center justify-between">
                  <NavLink
                    to={cafeData.website}
                    className="flex flex-row gap-2 cursor-pointer"
                  >
                    <img
                      src={globe}
                      alt="Globe"
                    />
                    <div className="w-full text-black hover:underline">
                      {cafeData.website}
                    </div>
                  </NavLink>
                </div>
              )}

              {cafeData.instagram_link && (
                <NavLink
                  to={cafeData.instagram_link}
                  className="flex flex-row gap-2 cursor-pointer"
                >
                  <img
                    src={insta}
                    alt="Instagram"
                  />
                  <div className="w-full text-black hover:underline">
                    {cafeData.instagram_link}
                  </div>
                </NavLink>
              )}

              {cafeData.facebook_link && (
                <NavLink
                  to={cafeData.facebook_link}
                  className="flex flex-row gap-2"
                >
                  <img
                    src={facebook}
                    alt="Facebook"
                  />
                  <div className="w-full text-black hover:underline">
                    {cafeData.facebook_link}
                  </div>
                </NavLink>
              )}
            </div>
          </>
        )}

        {Object.keys(cafeData.other_social_links).length > 0 && (
          <>
            <div className="font-bold text-lg mt-8">Other Social links</div>
            <div className="flex flex-col mt-4 space-y-4">
              {Object.entries(cafeData.other_social_links).map(
                ([key, value]) => (
                  <NavLink
                    key={key}
                    to={value}
                    className="w-full flex flex-row gap-2 cursor-pointer"
                  >
                    <div className="text-black capitalize font-bold">{key}</div>
                    <div className="text-black hover:underline">{value}</div>
                  </NavLink>
                )
              )}
            </div>
          </>
        )}
      </section>

      <hr className="border-t border-gray-300 my-4" />

      <section
        id="OrderOnline"
        className="social px-5 mb-4"
      >
        <div className="font-bold text-lg mb-8">Order Online</div>
        <div className="flex items-center justify-between mt-4 gap-x-4">
          <NavLink
            to={cafeData.swiggy_link}
            className="flex-grow"
          >
            <button className="w-full h-10 flex items-center justify-center rounded-lg shadow-md">
              <img
                src={swiggy}
                className="w-20 h-20 object-contain"
                alt="Swiggy"
              />
            </button>
          </NavLink>

          <NavLink
            to={`tel:${cafeData.phone}`}
            className="flex-grow"
          >
            <button
              className="w-full h-10 flex items-center justify-center bg-purple-500 rounded-lg text-white shadow-md"
              style={{ boxShadow: "2px 3px 0px 0px #000000" }}
            >
              Call Us
            </button>
          </NavLink>

          <NavLink
            to={cafeData.zomato_link}
            className="flex-grow"
          >
            <button className="w-full h-10 flex items-center justify-center rounded-lg shadow-md">
              <img
                src={zomato}
                className="w-20 h-20 object-contain"
                alt="Zomato"
              />
            </button>
          </NavLink>
        </div>
      </section>

      <hr className="border-t border-gray-300 my-4" />
      <section
        id="Offer"
        className="social px-5 mb-4"
      >
        <div className="font-bold text-lg mb-8">Offer</div>
        <div className="mb-6">
          {offers.length > 0 ? (
            <div className="flex overflow-x-auto gap-4 pb-4">
              <div className="flex gap-4 flex-nowrap">
                {offers
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((offer, index) => (
                    <div
                      key={index}
                      className="flex-shrink-0 w-[320px]"
                      onClick={() => handleOfferClick(offer)}
                    >
                      {offer.type === "card" ? (
                        <div
                          key={offer.id}
                          className="w-full"
                        >
                          <Offercard
                            offer={offer}
                            cafeName={cafeData.name}
                          />
                        </div>
                      ) : (
                        <div className="border-2 py-2 w-[320px] h-auto border-white px-2 pt-1 rounded-lg shadow-lg">
                          <div className="w-full rounded-lg shadow-md overflow-hidden">
                            <img
                              src={
                                offer.posterImage
                                  ? `data:image/webp;base64,${offer.posterImage}`
                                  : `${offer.imageUri}`
                              }
                              alt={`Offer ${index + 1}`}
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <div className="flex items-center justify-center gap-x-6">
                            {offer.addToSwiggy === "true" && (
                              <img
                                src={swiggy}
                                alt="Swiggy"
                                className="w-10 h-24"
                              />
                            )}
                            {offer.addToZomato === "true" && (
                              <img
                                src={zomato}
                                alt="Zomato"
                                className="w-24 h-24"
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <p>No active offers at the moment.</p>
          )}
        </div>
      </section>

      <hr className="border-t border-gray-300 my-4" />

      <section
        id="Gallery"
        className="social px-5 mb-4"
      >
        <div className="font-bold text-lg mb-8">Gallery</div>
        <div className="mb-6">
          {galleryImages.length > 0 ? (
            <div className="flex overflow-x-auto rounded-lg gap-4 pb-4">
              <div className="flex gap-4 flex-nowrap">
                {galleryImages.map((image, index) => (
                  <img
                    key={index}
                    src={`data:image/webp;base64,${image}`}
                    alt={`Gallery image ${index + 1}`}
                    className="w-20 h-20 object-cover rounded flex-shrink-0 cursor-pointer"
                    onClick={() => handleImageClick(index)} // Image click sets index
                  />
                ))}
              </div>
            </div>
          ) : (
            <p>No gallery images available.</p>
          )}
        </div>
      </section>

      <hr className="border-t border-gray-300 my-4" />

      <section
        id="Reviews"
        className="social px-5 mb-4"
      >
        <div className="font-bold text-lg">Reviews</div>
        {/* <RatingCard
          rating={cafeData.rating}
          count={cafeData.ratingcount}
        /> */}
        <div className="overflow-x-auto whitespace-nowrap">
          {stats &&
            stats.questionStats.map((question) => (
              <div
                key={question.question_id}
                className="rounded-lg p-2 inline-block  w-[80%]"
              >
                <RatingCard
                  rating={question.average_rating}
                  count={question.number_of_ratings}
                  question={question.question_text}
                />
              </div>
            ))}
        </div>
      </section>

      <hr className="border-t border-gray-300 my-4" />

      <section
        id="Contact"
        className="social px-5 mb-4"
      >
        <div className="font-bold text-lg">Contact</div>
        <Contact cafeDetails={cafeData} />
      </section>
      {selectedImageIndex !== null && (
        <GalleryPopup
          images={galleryImages} // Array of images in base64 format
          currentIndex={selectedImageIndex} // Index of the clicked image
          onClose={() => setSelectedImageIndex(null)} // Close popup
        />
      )}
      {selectedOffer && (
        <OfferPopup
          offer={selectedOffer}
          onClose={() => {
            setSelectedOffer(null);
          }}
          cafeName={cafeData.name}
        />
      )}

      <BottomMenu page="My page" />
    </div>
  );
};

export default FrontPage;
